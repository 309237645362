const newsTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'ARTIGO',
    label: 'Artigo',
  },
  {
    value: 'NOTÍCIAS',
    label: 'Notícias',
  },
];

export default newsTypes;
