const genres = [
  {
    value: 'FEMININO',
    label: 'Feminino',
  },
  {
    value: 'MASCULINO',
    label: 'Masculino',
  },
  {
    value: 'OUTROS',
    label: 'Outros',
  },
];

export default genres;
