const ComunicTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'COMUNICADO',
    label: 'Comunicado',
  },
  {
    value: 'INFORMATIVO',
    label: 'Informativo',
  },
];

export default ComunicTypes;
