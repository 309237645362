const fichaMinutes = [

  {
    title: 'DETALHES DO REGISTRO',
    lines: [
      {
        items: [
          {
            id: 'number',
            label: 'Número',
          },
        ],
      },

      {
        items: [
          {
            id: 'type',
            label: 'Tipo',
          },
        ],
      },

      {
        items: [
          {
            id: 'description',
            label: 'Descrição',

          },
        ],
      },

      {
        items: [
          {
            id: 'archive_1',
            label: 'Arquivo 1',
          },
        ],
      },

      {
        items: [
          {
            id: 'archive_2',
            label: 'Arquivo 2',

          },
        ],
      },
    ],
  },
];
export default fichaMinutes;
