const modelsTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'REQUERIMENTOS ADMINISTRATIVOS',
    label: 'Requerimentos Administrativos',
  },
  {
    value: 'PETIÇÕES INICIAIS',
    label: 'Petições Iniciais',
  },
  {
    value: 'JURISPRUDÊNCIA',
    label: 'Jurisprudência',
  },
];

export default modelsTypes;
