const civilStates = [
  {
    value: 'SOLTEIRO(A)',
    label: 'Solteiro(a)',
  },
  {
    value: 'CASADO(A)',
    label: 'Casado(a)',
  },
  {
    value: 'DIVORCIADO(A)',
    label: 'Divorciado(a)',
  },
  {
    value: 'DESQUITADO(A)',
    label: 'Desquitado(a)',
  },
  {
    value: 'OUTROS',
    label: 'Outros',
  },
];

export default civilStates;
