const actionTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'ADMINISTRATIVAS',
    label: 'Administrativas',
  },
  {
    value: 'JUDICIAIS',
    label: 'Judiciais',
  },
];

export default actionTypes;
