const office = [
  {
    value: '',
    label: '',
  },
  {
    value: 'JUIZ FEDERAL',
    label: 'Juiz Federal',
  },
  {
    value: 'JUIZ FEDERAL SUBSTITUTO',
    label: 'Juiz Federal Substituto',
  },
  {
    value: 'JUIZ FEDERAL APOSENTADO',
    label: 'Juiz Federal Aposentado',
  },
  {
    value: 'DESEMBARGADOR FEDERAL',
    label: 'Desembargador Federal',
  },
  {
    value: 'DESEMBARGADOR FEDERAL SUBSTITUTO',
    label: 'Desembargador Federal Substituto',
  },
  {
    value: 'DESEMBARGADOR FEDERAL APOSENTADO',
    label: 'Desembargador Federal Aposentado',
  },
];

export default office;
