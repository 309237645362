const status = [
  {
    value: 'A',
    label: 'A',
  },
  {
    value: 'E',
    label: 'E',
  },
];

export default status;
