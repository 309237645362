export const initialQuizzState = {
  title: '',
  description: '',
  openingDate: '',
  closingDate: '',
  options: [],
};

export const initialQuizzErrorState = {
  title: false,
  description: false,
  openingDate: false,
  closingDate: false,
  options: false,
  toVote: false,
};
