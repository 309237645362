export const initialAssociateState = {
  nome: '',
  user: '',
  cargo: '',
  nacionalidade: '',
  cpf: '',
  nascimento: '',
  naturalidade: '',
  sexo: '',
  estadoCivil: '',
  conjuge: '',
  nascimentoConjuge: '',
  filhos: '',
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
  lotacao: '',
  atuacao: '',
  cepFuncional: '',
  enderecoFuncional: '',
  numeroFuncional: '',
  complementoFuncional: '',
  bairroFuncional: '',
  cidadeFuncional: '',
  estadoFuncional: '',
  telefone: '',
  fax: '',
  celular: '',
  email: '',
  emailListaRejufe: '',
  emailListaAscom: '',
  admissao: '',
};

export const initialAssociateErrorState = {
  nome: false,
  user: false,
  cargo: false,
  nacionalidade: false,
  cpf: false,
  nascimento: false,
  naturalidade: false,
  sexo: false,
  estadoCivil: false,
  conjuge: false,
  nascimentoConjuge: false,
  filhos: false,
  cep: false,
  endereco: false,
  numero: false,
  bairro: false,
  cidade: false,
  estado: false,
  lotacao: false,
  atuacao: false,
  cepFuncional: false,
  enderecoFuncional: false,
  numeroFuncional: false,
  bairroFuncional: false,
  cidadeFuncional: false,
  estadoFuncional: false,
  telefone: false,
  fax: false,
  celular: false,
  email: false,
  emailListaRejufe: false,
  emailListaAscom: false,
  admissao: false,
  variavel1: false,
  variavel2: false,
};

export const initialNewsState = {
  section: 'INTRANET',
  type: '',
  title: '',
  description: '',
  archive_1: undefined,
  archive_2: undefined,
  photos: undefined,
  status: 'A',
};

export const initialNewsErrorState = {
  type: false,
  title: false,
  description: false,
  archive_1: false,
  archive_2: false,
  photos: false,
  date: false,
  status: false,
};

export const initialComunicState = {
  number: '',
  type: '',
  description: '',
  archive_1: undefined,
  archive_2: undefined,
};

export const initialComunicErrorState = {
  number: false,
  type: false,
  description: false,
  archive_1: false,
  archive_2: false,
};

export const initialMinutesState = {
  number: '',
  type: '',
  description: '',
  archive_1: undefined,
  archive_2: undefined,
};

export const initialActionsState = {
  numberAction: '',
  type: '',
  description: '',
  archive_1: undefined,
  archive_2: undefined,
};

export const initialMinutesErrorState = {
  number: false,
  type: false,
  description: false,
  archive_1: false,
  archive_2: false,
};

export const initialActionsErrorState = {
  numberAction: false,
  type: false,
  description: false,
  archive_1: false,
  archive_2: false,
};

export const initialModelsState = {
  numberModels: '',
  type: '',
  description: '',
  archive_1: undefined,
  archive_2: undefined,
};

export const initialModelsErrorState = {
  numberModels: false,
  type: false,
  description: false,
  archive_1: false,
  archive_2: false,
};

export const initialAccountabilityState = {
  date: '',
  title: '',
  description: '',
  archive_1: undefined,
};

export const initialAccountabilityErrorState = {
  date: false,
  title: false,
  description: false,
  archive_1: false,
};
