const recordNews = [
  {
    title: 'DETALHES DO REGISTRO',
    lines: [
      {
        items: [
          {
            id: 'date',
            label: 'Postagem',
          },
        ],
      },

      {
        items: [
          {
            id: 'type',
            label: 'Tipo',
          },
        ],
      },

      {
        items: [
          {
            id: 'title',
            label: 'Titulo',

          },
        ],
      },

      {
        items: [
          {
            id: 'archive_1',
            label: 'Arquivo 1',
          },
        ],
      },

      {
        items: [
          {
            id: 'archive_2',
            label: 'Arquivo 2',

          },
        ],
      },

      {
        items: [
          {
            id: 'description',
            label: 'Descrição',

          },
        ],
      },

      {
        items: [
          {
            id: 'photos',
            label: 'Imagem',

          },
        ],
      },
    ],
  },
];
export default recordNews;
