import React from 'react';

function AlteracoesExclusoes() {
  return (
    <div>
      <h1>Alteracoes e Exclusoes</h1>
    </div>
  );
}

export default AlteracoesExclusoes;
