const allocation = [
  {
    value: 'TRF 5',
    label: 'TRF 5',
  },
  {
    value: 'CE',
    label: 'Ceará',
  },
  {
    value: 'RN',
    label: 'Rio Grande do Norte',
  },
  {
    value: 'PB',
    label: 'Paraíba',
  },
  {
    value: 'PE',
    label: 'Pernambuco',
  },
  {
    value: 'AL',
    label: 'Alagoas',
  },
  {
    value: 'SE',
    label: 'Sergipe',
  },
];

export default allocation;
