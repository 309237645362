import * as React from 'react';
import './Loading.css';

export default function LinearColor() {
  return (
    <div className="linearColorContainer">
      <p className="loadingP">Carregando</p>
    </div>
  );
}
