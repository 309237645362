const MinutesTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'ATAS',
    label: 'Atas',
  },
  {
    value: 'EDITAIS',
    label: 'Editais',
  },
];

export default MinutesTypes;
